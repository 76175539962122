/* eslint-disable react/no-unstable-nested-components */
import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import type { RepliedToCommentNotification } from "components/engagement/notifications/types";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import LinkToAsset from "../elements/links/LinkToAsset";
import LinkToComment from "../elements/links/LinkToComment";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";
import safeCommentOrReplyPreamble from "../utils/safeCommentOrReplyPreamble";

interface IRepliedToCommentNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: RepliedToCommentNotification;
}

export default function RepliedToCommentNotificationListItem({
  notification,
}: IRepliedToCommentNotificationListItemProps) {
  // Shouldn't happen but just making sure so it doesn't break when people are using it
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  // `preventPropagation` is set to `true` because we don't want to mark the notification as read if they click in the
  // asset's link
  const linkToAsset = <LinkToAsset asset={topActivity.asset} preventPropagation={true} />;
  // A single reply from a single user (actor)
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.reply.data.bodyAsPlainText);
  const sentence = (
    <FormattedMessage
      defaultMessage="{actor_count, plural, one {respondeu ao seu comentário <i>{replyPreamble}</i> na área de discussões da} other {responderam ao seu comentário <i>{replyPreamble}</i> na área de discussões da}}"
      description="Notificações"
      id="kuGT8e"
      values={{ actor_count: notification.actor_count, replyPreamble, i: (text) => <i>{text}</i> }}
    />
  );

  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          <LinkToComment activity={topActivity}>
            {actors} {sentence}
          </LinkToComment>{" "}
          {linkToAsset}
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToComment activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToComment>
        </div>
      </div>
    </NotificationListItem>
  );
}
