/* eslint-disable react/no-unstable-nested-components */
import LinkToPost from "components/engagement/notifications/elements/links/LinkToPost";
import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import type { RepliedToPostNotification } from "components/engagement/notifications/types";
import safeCommentOrReplyPreamble from "components/engagement/notifications/utils/safeCommentOrReplyPreamble";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";

interface IRepliedToPostNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: RepliedToPostNotification;
}

export default function RepliedToPostNotificationListItem({ notification }: IRepliedToPostNotificationListItemProps) {
  const intl = useIntl();
  // Shouldn't happen but just making sure so it doesn't break when people are using it
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.reply.data.bodyAsPlainText);

  const hasObjectData =
    topActivity &&
    topActivity.object &&
    typeof topActivity.object === "object" &&
    topActivity.object.data &&
    typeof topActivity.object.data === "object";

  let postArea = "Post Area Not Found";

  if (hasObjectData && topActivity.object.data.contextType === "IRPF_FORUM") {
    postArea = intl.formatMessage({
      defaultMessage: "na área do Fórum Irpf",
      description: "Notificações",
      id: "hoxo/R",
    });
  }

  if (hasObjectData && topActivity.object.data.contextType === "BLACK_FORUM") {
    postArea = intl.formatMessage({
      defaultMessage: "na área da Comunidade Black",
      description: "Notificações",
      id: "Glwvo7",
    });
  }

  if (hasObjectData && topActivity.object.data.contextType === "BLACK_LESSON") {
    postArea = intl.formatMessage({
      defaultMessage: "na área Fundamentei Black",
      description: "Notificações",
      id: "gOs6d/",
    });
  }

  const setence = (
    <FormattedMessage
      defaultMessage="{actor_count, plural, one {respondeu ao seu comentário <i>{replyPreamble}</i> {postArea}} other {responderam ao seu comentário <i>{replyPreamble}</i> {postArea}}}"
      id="/WcanF"
      description="Notificações"
      values={{
        actor_count: notification.actor_count,
        replyPreamble,
        postArea,
        i: (text) => <i>{text}</i>,
      }}
    />
  );

  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          <LinkToPost activity={topActivity}>
            {actors} {setence}
          </LinkToPost>{" "}
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToPost activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToPost>
        </div>
      </div>
    </NotificationListItem>
  );
}
